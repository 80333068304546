import React from "react";
import classes from './Rules.module.scss';
import { useTranslation } from "react-i18next";

const cards = [
    {
        text: 'exchange_rules_text_01',
    },
    {
        text: 'exchange_rules_text_02',
    },
    {
        text: 'exchange_rules_text_03',
    },
    {
        text: 'exchange_rules_text_04',
    },
    {
        text: 'exchange_rules_text_05',
    },
];

const Rules = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.rules}>
                <h2 className={`${classes.title} font-40`}>
                    {t('exchange_rules_title')}
                </h2>
                <div className={`${classes.cards} m-content`}>
                    {cards.map((card, index) =>
                        <div key={index} className={classes.card}>
                            <p className={classes.cardIndex}>
                                {index + 1}
                            </p>
                            <div className={classes.cardLine}></div>
                            <p className={`${classes.cardText} font-17`}>
                                {t(card.text)}
                            </p>
                        </div>                    
                    )}
                </div>
            </div>
        </section>
    );
};

export default Rules;