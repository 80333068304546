import React, { useEffect, useRef } from "react";
import classes from './UnderBanner.module.scss';
import { useTranslation } from "react-i18next";
import Button from "../../GlobalComponents/Button/Button";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
import CustomEase from "gsap/CustomEase";
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(CustomEase);
gsap.config({
  force3D: true,
});

const UnderBanner = () => {
    const { t } = useTranslation();
    const lineRef_01 = useRef(null);
    const lineRef_02 = useRef(null);
    const lineRef_03 = useRef(null);
    const lineRef_04 = useRef(null);
    const lineRef_05 = useRef(null);
    const cards = [
        {
            ref: lineRef_01,
            text: 'home_underbanner_li_01',
            classes: classes.card_01,
        },
        {
            ref: lineRef_02,
            text: 'home_underbanner_li_02',
            classes: classes.card_02,
        },
        {
            ref: lineRef_03,
            text: 'home_underbanner_li_03',
            classes: classes.card_03,
        },
        {
            ref: lineRef_04,
            text: 'home_underbanner_li_04',
            classes: classes.card_04,
        },
        {
            ref: lineRef_05,
            text: 'home_underbanner_li_05',
            classes: classes.card_05,
        },
    ];
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            const ease = 'expoScale(0.5,7,none)';
            // const ease = 'back.out(1.7)';
            gsap.timeline().to(lineRef_01.current, {
                height: 230,
                ease: ease,
                scrollTrigger: {
                    trigger: lineRef_01.current,
                    start: "bottom+=50px bottom",
                    end: "+=400px",
                    scrub: 5,
                    once: true,
                }
            });
            gsap.timeline().to(lineRef_02.current, {
                height: 397,
                ease: ease,
                scrollTrigger: {
                    trigger: lineRef_02.current,
                    start: "bottom+=50px bottom",
                    end: "+=300px",
                    scrub: 5,
                    once: true,
                }
            });
            gsap.timeline().to(lineRef_03.current, {
                height: 332,
                ease: ease,
                scrollTrigger: {
                    trigger: lineRef_03.current,
                    start: "bottom+=50px bottom",
                    end: "+=500px",
                    scrub: 5,
                    once: true,
                }
            });
            gsap.timeline().to(lineRef_04.current, {
                height: 397,
                ease: ease,
                scrollTrigger: {
                    trigger: lineRef_04.current,
                    start: "bottom+=50px bottom",
                    end: "+=600px",
                    scrub: 5,
                    once: true,
                }
            });
            gsap.timeline().to(lineRef_05.current, {
                height: 266,
                ease: ease,
                scrollTrigger: {
                    trigger: lineRef_05.current,
                    start: "bottom+=50px bottom",
                    end: "+=200px",
                    scrub: 5,
                    once: true,
                }
            });
        }
    }, []);
    return (
        <section className="container">
            <div className={classes.underBanner}>
                {cards.map((card, index) =>
                    <div 
                        key={index}
                        className={`${classes.card} ${card.classes}`}
                    >
                        <div ref={card.ref} className={classes.line}></div>
                        <p className={`${classes.text} font-20`}>
                            {t(card.text)}
                        </p>
                    </div>
                )}
                <div className={classes.btn}>
                    <Button dark>
                        {t('btn_connect_us')}
                    </Button>
                </div>
            </div>
        </section>
    );
};

export default UnderBanner;
