import React from "react";
import classes from './Advantages.module.scss';
import { useTranslation } from "react-i18next";
import Button from '../../GlobalComponents/Button/Button';

const list = [
    'home_adv_text_01',
    'home_adv_text_02',
    'home_adv_text_03',
    'home_adv_text_04',
    'home_adv_text_05',
    'home_adv_text_06',
];

const Advantages = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.advantages}>
                <h2 className={`${classes.title} font-50`}>
                    {t('home_adv_title')}
                </h2>
                <div className={`${classes.cards} m-content`}>
                    {list.map((text, index) =>
                        <div key={index} className={classes.card}>
                            <div className={classes.cardBoxShadow}></div>
                            <p className={`${classes.cardText} font-17`}>
                                {t(text)}
                            </p>
                        </div>
                    )}
                </div>
                <div className={classes.btn}>
                    <Button dark>
                        {t('btn_connect_us')}
                    </Button>
                </div>
            </div>
        </section>
    );
};

export default Advantages;
