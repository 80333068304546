import HomePage from '../pages/HomePage/HomePage';
import ExchangePage from '../pages/ExchangePage/ExchangePage';

export const routes = [
    { path: '/about-us', element: HomePage },
    { path: '/', element: ExchangePage },
    { path: '/:lang/about-us', element: HomePage },
    { path: '/:lang/', element: ExchangePage },
];

export const headerRoutes = [
    { id: 1, path: 'about-us', text: 'header_about_us' },
];
