import React from "react";
import classes from "./Button.module.scss";
import { Link, useParams } from "react-router-dom";

const Button = ({ children, dark = false, toExchange = false }) => {
  const { lang } = useParams();

  const toForm = () => {
    const element = document.getElementById("main_form");
    element.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
  };

  if (toExchange) {
    return (
      <Link
        to={`/${lang}/`} 
        className={`${classes.button} ${dark && classes.dark} no-select`}
      >
        <span>
          {children}
        </span>
      </Link>
    );
  }

  return (
    <div
      onClick={toForm} 
      className={`${classes.button} ${dark && classes.dark} no-select`}
    >
      <span>
        {children}
      </span>
    </div>
  );
};

export default Button;
