import _ from 'lodash';
import React, { useState, useEffect } from "react";
import classes from './Form.module.scss';
import { useTranslation, Trans } from "react-i18next";
import axios from 'axios';

const Form = () => {
    const { t } = useTranslation();
    const [ popupActive, setPopupActive ] = useState(false);

    const [ name, setName ] = useState('');
    const [ telegram, setTelegram ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ message, setMessage ] = useState('');
    const [ check, setCheck ] = useState(false);

    const [ nameValid, setNameValid ] = useState(true);
    const [ telegramValid, setTelegramValid ] = useState(true);
    // const [ emailValid, setEmailValid ] = useState(true);
    const [ messageValid, setMessageValid ] = useState(true);
    const [ checkValid, setCheckValid ] = useState(true);

    const setDataDefaultState = () => {
        setName('');
        setTelegram('');
        setEmail('');
        setMessage('');
        setCheck(false)
        setNameValid(true);
        // setEmailValid(true);
        setTelegramValid(true);
        setMessageValid(true);
        setCheckValid(true);
    };

    const validateString = (getter, setter) => {
        if (getter === '') {
            setter(false);
            return false;
        }
        setter(true);
        return true;
    };
    // const validateEmail = () => {
    //     const expression = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    //     if (!expression.test(String(email.toLocaleLowerCase()))) {
    //         setEmailValid(false);
    //         return false;
    //     }
    //     setEmailValid(true);
    //     return true;
    // };
    const validateCheck = () => {
        if (check === false) {
            setCheckValid(false);
            return false;
        }
        setCheckValid(true);
        return true;
    };

    const validateInputData = () => {
        let valid = true;
        const validArr = [];
        validArr.push(validateString(name, setNameValid));
        validArr.push(validateString(telegram, setTelegramValid));
        // validArr.push(validateEmail());
        validArr.push(validateString(message, setMessageValid));
        validArr.push(validateCheck());

        _.map(validArr, (item) => !item && (valid = false));

        return valid;
    };
    
    const sendMessage = async () => {
        const messageData = {
            name,
            telegram,
            email,
            message,
        };
        setDataDefaultState();
        try {
            const data = await axios.post('https://civbt.xyz/api/bot/wlcsntzchq', messageData);
            if (data.status === 200) {
                setPopupActive(true);
            } else {
                throw({ message: 'Something went wrong. Try again.' })
            }
        } catch (error) {
            alert(`${error.message}`)
        }
    };

    const submit = () => {
        if (validateInputData()) {
            sendMessage();
        }
        return;
    };

    useEffect(() => {
        if (!nameValid) {
            validateString(name, setNameValid);
        }
        // eslint-disable-next-line
    }, [ name ]);
    useEffect(() => {
        if (!telegramValid) {
            validateString(telegram, setTelegramValid);
        }
        // eslint-disable-next-line
    }, [ telegram ]);
    // useEffect(() => {
    //     if (!emailValid) {
    //         validateEmail();
    //     }
    //     // eslint-disable-next-line
    // }, [ email ]);
    useEffect(() => {
        if (!messageValid) {
            validateString(message, setMessageValid);
        }
        // eslint-disable-next-line
    }, [ message ]);
    useEffect(() => {
        if (!checkValid) {
            validateCheck()
        }
        // eslint-disable-next-line
    }, [ check ]);

    return (
        <section className={classes.formWrap} id="main_form">
            <div className="mt container">
            <div className={`${classes.form} ${popupActive && classes.formSuccess}`}>
                    <div className={classes.successPopup}>
                        <div className={classes.successPopupImg}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="23" viewBox="0 0 32 23" fill="none">
                                <path d="M11.625 22.8431L0.6875 11.9034L3.77844 8.8125L11.625 16.6569L28.2172 0.0625L31.3125 3.15781L11.625 22.8431Z" fill="#B1FE4B"/>
                            </svg>
                        </div>
                        <p className={[classes.successPopupText, 'font-17'].join(' ')}>
                            <Trans>
                                {t('home_form_success')}
                            </Trans>
                        </p>
                    </div>
                    <div className={classes.formContent}>
                        <div className={classes.inputs}>
                            <div className={`${classes.inputWrap} ${!nameValid && classes.borderRed}`}>
                                <input
                                    className={classes.input}
                                    type="text"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    placeholder={t('home_form_name')}
                                />
                            </div>
                            <div className={`${classes.inputWrap} ${!telegramValid && classes.borderRed}`}>
                                <input
                                    className={classes.input}
                                    type="text"
                                    value={telegram}
                                    onChange={(e) => setTelegram(e.target.value)}
                                    placeholder={t('home_form_telegram')}
                                />
                            </div>
                            {/* <div className={`${classes.inputWrap} ${!emailValid && classes.borderRed}`}> */}
                            <div className={classes.inputWrap}>
                                <input
                                    className={classes.input}
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder={t('home_form_email')}
                                />
                            </div>
                            <div className={`${classes.inputWrap} ${classes.textareaWrap} ${!messageValid && classes.borderRed}`}>
                                <textarea
                                    className={classes.input}
                                    type="text"
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    placeholder={t('home_form_message')}
                                />
                            </div>
                            <div className={`${classes.check} ${!checkValid && classes.checkRed}`}>
                                <div 
                                    className={`${classes.checkBox} ${check && classes.checkBoxActive} cursorHoverEl`} 
                                    onClick={() => setCheck((value) => !value)}
                                ></div>
                                <p className={classes.checkText}>
                                    <Trans components={{a: <a href="/docs/TERMS_&_CONDITIONS.pdf" target="_blank"></a>}}>
                                        {t('home_form_accept')}
                                    </Trans>
                                </p>
                            </div>
                        </div>
                        <div className={classes.btnWrap}>
                            <div onClick={submit} className={`${classes.button} cursorHoverEl`}>
                                <span>
                                    {t('btn_connect_us')}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Form;
