import React from "react";
import classes from './InNumbers.module.scss';
import { Trans, useTranslation } from "react-i18next";
import image_01 from '../../../assets/img/HomePage/InNumbers/image_01.png';
import image_02 from '../../../assets/img/HomePage/InNumbers/image_02.png';
import image_03 from '../../../assets/img/HomePage/InNumbers/image_03.png';

const cards = [
    {
        image: image_01,
        title: '25 M $',
        text: 'home_in_nums_text_01',
    },
    {
        image: image_02,
        title: '50 +',
        text: 'home_in_nums_text_02',
    },
    {
        image: image_03,
        title: '30+',
        text: 'home_in_nums_text_03',
    },
];

const InNumbers = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.inNumbers}>
                <h2 className={`${classes.title} font-50`}>
                    {t('home_in_nums_title')}
                </h2>
                <div className={classes.cards}>
                    {cards.map((card, index) =>
                        <div key={index} className={classes.card}>
                            <p className={classes.cardTitle}>
                                {card.title}
                            </p>
                            <p className={classes.cardText}>
                                <Trans>
                                    {t(card.text)}
                                </Trans>
                            </p>
                            <div className={classes.cardLine}></div>
                            <img className={classes.cardImage} src={card.image} alt=''/>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default InNumbers;
