import _ from 'lodash';
import React, { useEffect, useState } from "react";
import classes from './Exchange.module.scss';
import { useTranslation, Trans } from "react-i18next";
import axios from 'axios';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import searchIcon from '../../../assets/img/ExchangePage/search.svg';
import { Dropdown } from 'primereact/dropdown';

const ICONS_HOST = 'https://usbpsp.com/currency_icons';
const ICON_FORMAT = 'png';
const CEIL_VALUE = 3;

const Exchange = () => {
    const { t } = useTranslation();
    const [ domLoaded, setDomLoaded ] = useState(false);
    const [ popupActive, setPopupActive ] = useState(false);
    
    // watch api calls
    const [ currenciesLoaded, setCurrenciesLoaded ] = useState(false);
    const [ rateLoaded, setRateLoaded ] = useState(false);
    // rate column error message
    const [ rateErrorMessage, setRateErrorMessage ] = useState('');

    // currencies list
    const [ currencies, setCurrencies ] = useState([]);
    // pair rate
    const [ rateReceive, setRateReceive ] = useState(1);
    const [ receiveRate, setReceiveRate ] = useState(1);
    const [ sendRate, setSendRate ] = useState(1);

    // selected send currency
    const [ sendCurr, setSendCurr ] = useState({});
    // selected receive currency
    const [ receiveCurr, setReceiveCurr ] = useState({});

    // list filters
    const [ sendFilter, setSendFilter ] = useState('');
    const [ receiveFilter, setReceiveFilter ] = useState('');

    // form states
    const [ sendAmount, setSendAmount ] = useState(null);
    const [ receiveAmount, setReceiveAmount ] = useState(0);
    const [ name, setName ] = useState('');
    const [ telegram, setTelegram ] = useState('');
    const [ receiveWallet, setReceiveWallet ] = useState('');
    const [ termsAgree, setTermsAgree ] = useState(false);
    // form states validation
    const [ sendAmountValid, setSendAmountValid ] = useState(true);
    const [ receiveAmountValid, setReceiveAmountValid ] = useState(true);
    const [ nameValid, setNameValid ] = useState(true);
    const [ telegramValid, setTelegramValid ] = useState(true);
    const [ receiveWalletValid, setReceiveWalletValid ] = useState(true);
    const [ termsAgreeValid, setTermsAgreeValid ] = useState(true);

    const setDataDefaultState = () => {
        setSendAmount(0);
        setReceiveAmount(0);
        setName('');
        setTelegram('');
        setReceiveWallet('');
        setTermsAgree(false);
    };

    /**
     * Form inputs validation
     */
    const validateString = (getter, setter) => {
        if (getter === '') {
            setter(false);
            return false;
        }
        setter(true);
        return true;
    };
    const validateCheck = () => {
        if (termsAgree === false) {
            setTermsAgreeValid(false);
            return false;
        }
        setTermsAgreeValid(true);
        return true;
    };
    const validateInputData = () => {
        let valid = true;
        const validArr = [];
        validArr.push(validateString(name, setNameValid));
        validArr.push(validateString(telegram, setTelegramValid));
        validArr.push(validateString(receiveWallet, setReceiveWalletValid));
        validArr.push(validateCheck());

        _.map(validArr, (item) => !item && (valid = false));

        return valid;
    };

    const sendMessage = async () => {
        const messageData = {
            curr_send: {
                name: sendCurr.name,
                amount: sendAmount,
            },
            curr_receive: {
                name: receiveCurr.name,
                amount: receiveAmount,
            },
            name,
            telegram,
            receive_wallet: receiveWallet,
        };
        setDataDefaultState();
        try {
            const data = await axios.post('https://civbt.xyz/api/bot/aGrOQBUJOK', messageData);
            if (data.status === 200) {
                setPopupActive(true);
                setTimeout(() => {
                    setPopupActive(false);
                }, 10000);
            } else {
                throw({ message: 'Something went wrong. Try again.' })
            }
        } catch (error) {
            alert(`${error.message}`)
        }
    };

    const submit = () => {
        if (validateInputData()) {
            sendMessage();
        }
        return;
    };

    const afterPoint = x => ( (x.toString().includes('.')) ? (x.toString().split('.').pop().length) : (0) );

    /**
     * Calculate number of '0' after point
     * @param {number} value 
     * @returns {number}
     */
    const zeroCount = (value) => {
        let i = 1;
        for (i; i >= 0; i++) {
            const pow = Math.pow(10, i);
            if ((value * pow) >= 1 ) {
                break;
            }
        }
        return i;
    };

    /**
     * Handle send amount input, calculate receive amount
     * @param {number} newSendAmount 
     */
    const handleSendUpdate = (newSendAmount) => {
        if (newSendAmount < 0)
            newSendAmount *= -1;

        let newReceiveAmount;
        setSendAmount(newSendAmount);

        if (sendRate === 1) {
            newReceiveAmount = newSendAmount * receiveRate;
            if (newReceiveAmount >= 0.1 || newReceiveAmount === 0 || !newReceiveAmount) {
                newReceiveAmount = _.floor(newReceiveAmount, CEIL_VALUE);
            } else {
                const zeroCnt = zeroCount(newReceiveAmount);
                newReceiveAmount = _.floor(newReceiveAmount, CEIL_VALUE + zeroCnt);
            }
        } else {
            newReceiveAmount = newSendAmount / sendRate;
            if (newReceiveAmount !== 0) {
                const zeroCnt = zeroCount(newReceiveAmount);
                newReceiveAmount = _.floor(newReceiveAmount, CEIL_VALUE + zeroCnt);
            }
        }
        setReceiveAmount(newReceiveAmount);
    };

    const handleReceiveUpdate = () => {

    };

    /**
     * Get full currency list and save it to 'currencies'
     */
    const getCurrencies = async () => {
        try {
            const { data } = await axios.get('https://bc-py-api.civbt.xyz/list');
            if (data) {
                const newCurrencies = _.map(_.keys(data), (key) => data[key]);
                setCurrencies(newCurrencies);
                setCurrenciesLoaded(true);
            }
        } catch (error) {
            console.log('ERROR ======', error);
            alert(error);
        }
    };

    /**
     * Get pair rate and save it to 'rateReceive'
     */
    const getCurrenciesRate = async (send, receive) => {
        setRateLoaded(false);

        if (send.id === receive.id) {
            setRateErrorMessage(t('exchange_calc_pair_not_available'));
            setRateLoaded(true);
            return;
        }

        try {
            const { data } = await axios.get(`https://bc-py-api.civbt.xyz/get-currency?give_id=${send.id}&get_id=${receive.id}`);

            if (data.length === 0) {
                setRateErrorMessage(t('exchange_calc_pair_not_available'));
            }

            if (data.length > 0) {
                const { rate, rate_give, rate_receive } = data[0];
                setRateErrorMessage('');
                setRateReceive(rate);
                setSendRate(_.ceil(rate_give, CEIL_VALUE));
                setReceiveRate(_.ceil(rate_receive, CEIL_VALUE));
            }

            setRateLoaded(true);
        } catch (error) {
            console.log('ERROR ======', error);
            setRateErrorMessage(error);
            setRateLoaded(true);
        }
    };

    useEffect(() => {
        if (domLoaded) {
            getCurrencies();
        }
    }, [ domLoaded ]);

    useEffect(() => {
        setDomLoaded(true);
    }, []);

    /**
     * Set default currencies when we get currency list
     */
    useEffect(() => {
        if (currencies.length > 0) {
            setSendCurr(currencies[92]);
            setReceiveCurr(currencies[9]);
        }
    }, [ currencies ]);

    /**
     * Get pair rate when user change selected currency
     */
    useEffect(() => {
        if (currencies.length > 0) {
            getCurrenciesRate(sendCurr, receiveCurr);
        }
    }, [ sendCurr, receiveCurr ]);

    /**
     * Calculate receive amount when pair change
     */
    useEffect(() => {
        handleSendUpdate(sendAmount);
    }, [ rateReceive ]);

    /**
     * Form inpunt validation
     */
    useEffect(() => {
        if (!nameValid) {
            validateString(name, setNameValid);
        }
        // eslint-disable-next-line
    }, [ name ]);
    useEffect(() => {
        if (!telegramValid) {
            validateString(telegram, setTelegramValid);
        }
        // eslint-disable-next-line
    }, [ telegram ]);
    useEffect(() => {
        if (!receiveWalletValid) {
            validateString(receiveWallet, setReceiveWalletValid);
        }
        // eslint-disable-next-line
    }, [ receiveWallet ]);
    useEffect(() => {
        if (!termsAgreeValid) {
            validateCheck()
        }
        // eslint-disable-next-line
    }, [ termsAgree ]);


    const selectedCurrencyTemplate = (option, props) => {
        if (option) {
            return (
                <div className="flex align-items-center gap-srch">
                    <img alt={option.name} src={`${ICONS_HOST}/${option.code}.${ICON_FORMAT}`} className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '20px' }} />
                    <div>{option.name}</div>
                </div>
            );
        }

        return <span>{props.placeholder}</span>;
    };

    const currencyOptionTemplate = (option) => {
        return (
            <div className="flex align-items-center gap-srch">
                <img alt={option.name} src={`${ICONS_HOST}/${option.code}.${ICON_FORMAT}`} style={{ width: '20px' }} />
                <div>{option.name}</div>
            </div>
        );
    };


    return (
        <section className={classes.exchangeWrap}>
            <div className="container">
                <div className={classes.exchange}>
                    <div className={`${classes.col} ${classes.colSend}`}>
                        <LoadingSpinner hidden={currenciesLoaded} />
                        <div className={`${classes.colHeader} font-20`}>
                            <input
                                type="text"
                                placeholder={t('exchange_calc_you_send')}
                                value={sendFilter}
                                onChange={(e) => setSendFilter(e.target.value)}
                                className={classes.filterInput}
                            />
                            <img className={classes.searchIcon} src={searchIcon} alt=''/>
                            <p className={classes.mobColTitle}>
                                {t('exchange_calc_you_send')}
                            </p>
                        </div>
                        <ul className={classes.colList}>
                            {currencies.filter(curr => _.toLower(curr.name).includes(_.toLower(sendFilter))).map((curr, index) =>
                                <li 
                                    key={index} 
                                    className={`${classes.listItem} ${sendCurr.id === curr.id && classes.active} font-17 no-select`}
                                    onClick={() => setSendCurr(curr)}
                                >
                                    <img className={classes.currIcon} src={`${ICONS_HOST}/${curr.code}.${ICON_FORMAT}`} alt=''/>
                                    <p className={classes.currTitle}>
                                        {curr.name}
                                    </p>
                                </li>
                            )}
                        </ul>
                        <div className={classes.mobColList}>
                            <Dropdown 
                                value={sendCurr} 
                                onChange={(e) => setSendCurr(e.value)} 
                                options={currencies} 
                                optionLabel="name" 
                                placeholder="Select currency..."
                                filter 
                                valueTemplate={selectedCurrencyTemplate} 
                                itemTemplate={currencyOptionTemplate} 
                                className={classes.mobCurrDropdown}
                            />
                        </div>
                    </div>
                    <div className={`${classes.col} ${classes.colReceive}`}>
                        <LoadingSpinner hidden={currenciesLoaded} />
                        <div className={`${classes.colHeader} font-20`}>
                            <input
                                type="text"
                                placeholder={t('exchange_calc_you_receive')}
                                value={receiveFilter}
                                onChange={(e) => setReceiveFilter(e.target.value)}
                                className={classes.filterInput}
                            />
                            <img className={classes.searchIcon} src={searchIcon} alt=''/>
                            <p className={classes.mobColTitle}>
                                {t('exchange_calc_you_receive')}
                            </p>
                        </div>
                        <ul className={classes.colList}>
                            {currencies.filter(curr => _.toLower(curr.name).includes(_.toLower(receiveFilter))).map((curr, index) =>
                                <li 
                                    key={index} 
                                    className={`${classes.listItem} ${receiveCurr.id === curr.id && classes.active} font-17 no-select`}
                                    onClick={() => setReceiveCurr(curr)}
                                >
                                    <img className={classes.currIcon} src={`${ICONS_HOST}/${curr.code}.${ICON_FORMAT}`} alt=''/>
                                    <p className={classes.currTitle}>
                                        {curr.name}
                                    </p>
                                </li>
                            )}
                        </ul>
                        <div className={classes.mobColList}>
                            <Dropdown 
                                value={receiveCurr} 
                                onChange={(e) => setReceiveCurr(e.value)} 
                                options={currencies} 
                                optionLabel="name" 
                                placeholder="Select currency..."
                                filter 
                                valueTemplate={selectedCurrencyTemplate} 
                                itemTemplate={currencyOptionTemplate} 
                                className={classes.mobCurrDropdown}
                            />
                        </div>
                    </div>
                    <div className={`${classes.col} ${classes.colForm}`}>
                        <div className={`${classes.colHeader} font-20`}>
                            {t('exchange_calc_data_input')}
                        </div>
                        <LoadingSpinner hidden={rateLoaded} search />
                        <div className={`${classes.successPopup} ${popupActive && classes.popupActive}`}>
                            <div className={classes.successPopupImg}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="23" viewBox="0 0 32 23" fill="none">
                                    <path d="M11.625 22.8431L0.6875 11.9034L3.77844 8.8125L11.625 16.6569L28.2172 0.0625L31.3125 3.15781L11.625 22.8431Z" fill="#B1FE4B"/>
                                </svg>
                            </div>
                            <p className={[classes.successPopupText, 'font-17'].join(' ')}>
                                <Trans>
                                    {t('home_form_success')}
                                </Trans>
                            </p>
                        </div>
                        {_.isEmpty(rateErrorMessage) ?
                            <div className={`${classes.formWrap} ${(!rateLoaded || popupActive) && classes.blur5} font-17`}>
                                <p className={classes.rate}>
                                    <b>{t('exchange_calc_exchange_rate')}</b> <br/>
                                    {/* 1 {sendCurr.code} = {1 / rateReceive} {receiveCurr.code} */}
                                    {sendRate} {sendCurr.code} = {receiveRate} {receiveCurr.code}
                                </p>
                                <div className={classes.form}>
                                    <div className={classes.formCurrency}>
                                        <img className={classes.currIcon} src={`${ICONS_HOST}/${sendCurr.code}.${ICON_FORMAT}`} alt=''/>
                                        <p className={classes.currTitle}>
                                            {sendCurr.name}
                                        </p>
                                    </div>
                                    <div className={classes.inputWrap}>
                                        <p className={classes.label}>
                                            {t('exchange_calc_amount')}*:
                                        </p>
                                        <input
                                            type="number"
                                            placeholder={t('exchange_calc_amount')}
                                            value={sendAmount}
                                            // onChange={(e) => setSendAmount(e.target.value)}
                                            onChange={(e) => handleSendUpdate(e.target.value)}
                                            className={!sendAmountValid ? classes.incorrect : ''}
                                        />
                                    </div>
                                </div>
                                <div className={classes.form}>
                                    <div className={classes.formCurrency}>
                                        <img className={classes.currIcon} src={`${ICONS_HOST}/${receiveCurr.code}.${ICON_FORMAT}`} alt=''/>
                                        <p className={classes.currTitle}>
                                            {receiveCurr.name}
                                        </p>
                                    </div>
                                    <div className={classes.inputWrap}>
                                        <p className={classes.label}>
                                            {t('exchange_calc_amount')}*:
                                        </p>
                                        <input
                                            disabled
                                            type="number"
                                            placeholder={t('exchange_calc_amount')}
                                            value={receiveAmount}
                                            onChange={(e) => setReceiveAmount(e.target.value)}
                                            className={!receiveAmountValid ? classes.incorrect : ''}
                                        />
                                    </div>
                                    <div className={classes.inputWrap}>
                                        <p className={classes.label}>
                                            {t('exchange_calc_name')}*:
                                        </p>
                                        <input
                                            type="text"
                                            placeholder={t('exchange_calc_name')}
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            className={!nameValid ? classes.incorrect : ''}
                                        />
                                    </div>
                                    <div className={classes.inputWrap}>
                                        <p className={classes.label}>
                                            {t('exchange_calc_telegram')}*:
                                        </p>
                                        <input
                                            type="text"
                                            placeholder={t('exchange_calc_telegram')}
                                            value={telegram}
                                            onChange={(e) => setTelegram(e.target.value)}
                                            className={!telegramValid ? classes.incorrect : ''}
                                        />
                                    </div>
                                    <div className={classes.inputWrap}>
                                        <p className={classes.label}>
                                            {t('exchange_calc_wallet')} {receiveCurr.name}*:
                                        </p>
                                        <input
                                            type="text"
                                            placeholder={t('exchange_calc_wallet')}
                                            value={receiveWallet}
                                            onChange={(e) => setReceiveWallet(e.target.value)}
                                            className={!receiveWalletValid ? classes.incorrect : ''}
                                        />
                                    </div>
                                </div>
                                <div 
                                    onClick={submit}
                                    className={`${classes.sendBtn} font-17`}
                                >
                                    <span>
                                        {t('exchange_calc_btn_exchange')}
                                    </span>
                                </div>
                                <div className={classes.checkWrap}>
                                    <div 
                                        className={`${classes.check} ${termsAgree && classes.active} ${!termsAgreeValid && classes.incorrect}`}
                                        onClick={() => setTermsAgree(value => !value)}
                                    ></div>
                                    <p>
                                        <Trans components={{a: <a href="/docs/TERMS_&_CONDITIONS.pdf" target="_blank"></a>}}>
                                            {t('home_form_accept')}
                                        </Trans>
                                    </p>
                                </div>
                            </div>
                            :
                            <p className={classes.rateErrorMessage}>
                                <Trans>
                                    {rateErrorMessage}
                                </Trans>
                            </p>
                        }
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Exchange;
