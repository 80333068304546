import React from "react";
import classes from './Banner.module.scss';
import animation_b from '../../../assets/img/HomePage/Banner/animation_b.png';
import animation_s from '../../../assets/img/HomePage/Banner/animation_s.png';

const Carousel = () => {
    return (
        <div className={classes.slider}>
            <div className={classes.slideTrack}>
                <div className={classes.slide}>
                    <img src={animation_b} alt=''/>
                </div>
                <div className={classes.slide}>
                    <img src={animation_s} alt=''/>
                </div>
                <div className={classes.slide}>
                    <img src={animation_b} alt=''/>
                </div>
                <div className={classes.slide}>
                    <img src={animation_s} alt=''/>
                </div>
                <div className={classes.slide}>
                    <img src={animation_b} alt=''/>
                </div>
                <div className={classes.slide}>
                    <img src={animation_s} alt=''/>
                </div>
                <div className={classes.slide}>
                    <img src={animation_b} alt=''/>
                </div>
                <div className={classes.slide}>
                    <img src={animation_s} alt=''/>
                </div>
                <div className={classes.slide}>
                    <img src={animation_b} alt=''/>
                </div>
                <div className={classes.slide}>
                    <img src={animation_s} alt=''/>
                </div>
                <div className={classes.slide}>
                    <img src={animation_b} alt=''/>
                </div>
                <div className={classes.slide}>
                    <img src={animation_s} alt=''/>
                </div>
                <div className={classes.slide}>
                    <img src={animation_b} alt=''/>
                </div>
                <div className={classes.slide}>
                    <img src={animation_s} alt=''/>
                </div>
                <div className={classes.slide}>
                    <img src={animation_b} alt=''/>
                </div>
                <div className={classes.slide}>
                    <img src={animation_s} alt=''/>
                </div>
                <div className={classes.slide}>
                    <img src={animation_b} alt=''/>
                </div>
                <div className={classes.slide}>
                    <img src={animation_s} alt=''/>
                </div>
            </div>
        </div>
    );
};

export default Carousel;
