import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from '../../components/GlobalComponents/Header/Header';
import Footer from '../../components/GlobalComponents/Footer/Footer';
import SymbolsBg from "../../components/GlobalComponents/SymbolsBg/SymbolsBg";
import Rules from "../../components/ExchangePage/Rules/Rules";
import Observe from "../../components/ExchangePage/Observe/Observe";
import FollowRules from "../../components/ExchangePage/FollowRules/FollowRules";
import Form from "../../components/HomePage/Form/Form";
import Exchange from "../../components/ExchangePage/Exchange/Exchange";

const ExchangePage = () => {
    return (
        <HelmetProvider>
            <Helmet>
                <title></title>
                <meta name="title" content="" />
                <meta property="og:title" content="" />
                <meta property="twitter:title" content="" />
                
                <meta name="description" content=""/>
                <meta property="og:description" content=""/>
                <meta property="twitter:description" content="" />

                <meta property="og:image" content="preview.png" />
                <meta property="og:type" content="website" />
                <meta property="twitter:image" content="preview.png" />
                <meta property="twitter:card" content="summary_large_image" />
            </Helmet>
            <div className="ovf-hidden">
                <SymbolsBg />
                <Header />
                <Exchange />
                <Rules />
                <Observe />
                <FollowRules />
                <Form />
                <Footer />
            </div>
        </HelmetProvider>
    );
};

export default ExchangePage;
