import React, { useEffect, useRef } from "react";
import classes from './AssetTypes.module.scss';
import { Trans, useTranslation } from "react-i18next";
import eye from '../../../assets/img/HomePage/AssetTypes/eye.png';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
import CustomEase from "gsap/CustomEase";
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(CustomEase);
gsap.config({
  force3D: true,
});

const AssetTypes = () => {
    const { t } = useTranslation();
    const lineRef_01 = useRef(null);
    const lineRef_02 = useRef(null);
    const lineRef_03 = useRef(null);
    const lineRef_04 = useRef(null);
    const lineRef_05 = useRef(null);
    const cards = [
        {
            ref: lineRef_01,
            text: 'home_asset_types_li_01',
            classes: classes.card_01,
        },
        {
            ref: lineRef_02,
            text: 'home_asset_types_li_02',
            classes: classes.card_02,
        },
        {
            ref: lineRef_03,
            text: 'home_asset_types_li_03',
            classes: classes.card_03,
        },
        {
            ref: lineRef_04,
            text: 'home_asset_types_li_04',
            classes: classes.card_04,
        },
        {
            ref: lineRef_05,
            text: 'home_asset_types_li_05',
            classes: classes.card_05,
        },
    ];
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            const ease = 'expoScale(0.5,7,none)';
            gsap.timeline().to(lineRef_01.current, {
                width: '100%',
                ease: ease,
                scrollTrigger: {
                    trigger: lineRef_01.current,
                    start: "bottom+=50px bottom",
                    end: "+=200px",
                    scrub: 5,
                    once: true,
                }
            });
            gsap.timeline().to(lineRef_02.current, {
                width: '100%',
                ease: ease,
                scrollTrigger: {
                    trigger: lineRef_02.current,
                    start: "bottom+=50px bottom",
                    end: "+=200px",
                    scrub: 5,
                    once: true,
                }
            });
            gsap.timeline().to(lineRef_03.current, {
                width: '100%',
                ease: ease,
                scrollTrigger: {
                    trigger: lineRef_03.current,
                    start: "bottom+=50px bottom",
                    end: "+=200px",
                    scrub: 5,
                    once: true,
                }
            });
            gsap.timeline().to(lineRef_04.current, {
                width: '100%',
                ease: ease,
                scrollTrigger: {
                    trigger: lineRef_04.current,
                    start: "bottom+=50px bottom",
                    end: "+=50px",
                    scrub: 5,
                    once: true,
                }
            });
            gsap.timeline().to(lineRef_05.current, {
                width: '100%',
                ease: ease,
                scrollTrigger: {
                    trigger: lineRef_05.current,
                    start: "bottom+=50px bottom",
                    end: "+=50px",
                    scrub: 5,
                    once: true,
                }
            });
        }
    }, [])
    return (
        <section className="mt container">
            <div className={classes.assetTypes}>
                <h2 className={`${classes.title} font-50`}>
                    {t('home_asset_types_title')}
                </h2>
                <div className={classes.content}>
                    <div className={classes.eye}>
                        <img src={eye} alt=''/>
                    </div>
                    <div className={classes.cards}>
                        {cards.map((card, index) =>
                            <div 
                                key={index} 
                                className={`${classes.card} ${card.classes}`}
                            >
                                <p className={`${classes.text} font-20`}>
                                    <Trans>
                                        {t(card.text)}
                                    </Trans>
                                </p>
                                <div ref={card.ref} className={classes.line}></div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AssetTypes;
