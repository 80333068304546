import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from '../../components/GlobalComponents/Header/Header';
import Footer from '../../components/GlobalComponents/Footer/Footer';
import Advantages from "../../components/HomePage/Advantages/Advantages";
import Banner from "../../components/HomePage/Banner/Banner";
import AssetTypes from "../../components/HomePage/AssetTypes/AssetTypes";
import UnderBanner from "../../components/HomePage/UnderBanner/UnderBanner";
import bg from '../../assets/img/HomePage/bg.jpg';
import SymbolsBg from "../../components/GlobalComponents/SymbolsBg/SymbolsBg";
import InNumbers from "../../components/HomePage/InNumbers/InNumbers";
import WhyUs from "../../components/HomePage/WhyUs/WhyUs";
import Form from "../../components/HomePage/Form/Form";

const HomePage = () => {
    return (
        <HelmetProvider>
            <Helmet>
                <title></title>
                <meta name="title" content="" />
                <meta property="og:title" content="" />
                <meta property="twitter:title" content="" />
                
                <meta name="description" content=""/>
                <meta property="og:description" content=""/>
                <meta property="twitter:description" content="" />

                <meta property="og:image" content="preview.png" />
                <meta property="og:type" content="website" />
                <meta property="twitter:image" content="preview.png" />
                <meta property="twitter:card" content="summary_large_image" />
            </Helmet>
            <div className="ovf-hidden">
                <div className="home-bg">
                    <img src={bg} alt=''/>
                </div>
                <SymbolsBg />
                <Header />
                <Banner />
                <UnderBanner />
                <AssetTypes />
                <Advantages />
                <InNumbers />
                <WhyUs />
                <Form />
                <Footer />
            </div>
        </HelmetProvider>
    );
};

export default HomePage;
