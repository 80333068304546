import React from "react";
import classes from './LoadingSpinner.module.scss';

const LoadingSpinner = ({ hidden = false, search = false }) => {
    return !hidden && (
        <span className={`${
            search 
                ? classes.searchLoader 
                : classes.loader
        }`}></span>
    );
};

export default LoadingSpinner;
