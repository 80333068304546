import React from "react";
import classes from './FollowRules.module.scss';
import { useTranslation } from "react-i18next";

const FollowRules = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.followRules}>
                <h2 className={`${classes.title} font-40`}>
                    {t('exchange_follow_rules_title')}
                </h2>
                <div className={`${classes.content} font-17 m-content`}>
                    <ul className={classes.col}>
                        <li>
                            {t('exchange_follow_rules_text_01')}
                        </li>
                        <li>
                            {t('exchange_follow_rules_text_02')}
                        </li>
                        <li>
                            {t('exchange_follow_rules_text_03')}
                        </li>
                        <li>
                            {t('exchange_follow_rules_text_04')}
                        </li>
                    </ul>
                    <ul className={classes.col}>
                        <li>
                            {t('exchange_follow_rules_text_05')}
                        </li>
                        <li>
                            {t('exchange_follow_rules_text_06')}
                        </li>
                        <li>
                            {t('exchange_follow_rules_text_07')}
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    );
};

export default FollowRules;
