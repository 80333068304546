import React, { useEffect, useRef } from "react";
import classes from './Observe.module.scss';
import { useTranslation } from "react-i18next";
import image_01 from '../../../assets/img/ExchangePage/Observe/image_01.png';
import image_02 from '../../../assets/img/ExchangePage/Observe/image_02.png';
import Button from '../../GlobalComponents/Button/Button';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
import CustomEase from "gsap/CustomEase";
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(CustomEase);
gsap.config({
  force3D: true,
});

const Observe = () => {
    const { t } = useTranslation();
    const imageRef_01 = useRef(null);
    const imageRef_02 = useRef(null);
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            const ease = 'expoScale(0.5,7,none)';
            gsap.timeline().to(imageRef_01.current, {
                x: 0,
                y: 0,
                ease: ease,
                scrollTrigger: {
                    trigger: imageRef_01.current,
                    start: "center bottom",
                    end: "+=300px",
                    scrub: 5,
                    once: true,
                }
            });
            gsap.timeline().to(imageRef_02.current, {
                x: 0,
                y: 0,
                ease: ease,
                scrollTrigger: {
                    trigger: imageRef_01.current,
                    start: "center bottom",
                    end: "+=300px",
                    scrub: 5,
                    once: true,
                }
            });
        }
    }, []);
    return (
        <section className="mt container">
            <div className={classes.observe}>
                <div className={classes.content}>
                    <div className={`${classes.text} font-17`}>
                        <p>
                            {t('exchange_observe_text_01')}
                        </p>
                        <p>
                            {t('exchange_observe_text_02')}
                        </p>
                    </div>
                    <div className={classes.btn}>
                        <Button dark>
                            {t('btn_connect_us')}
                        </Button>
                    </div>
                </div>
                <div className={classes.images}>
                    <img ref={imageRef_01} className={`${classes.image} ${classes.image_01}`} src={image_01} alt=''/>
                    <img ref={imageRef_02} className={`${classes.image} ${classes.image_02}`} src={image_02} alt=''/>
                </div>
            </div>
        </section>
    );
};

export default Observe;
