import React, { useState } from "react";
import classes from './WhyUs.module.scss';
import { useTranslation } from "react-i18next";

const tabs = [
    {
        statusIcon: <svg width="26" height="46" viewBox="0 0 26 46" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M10.7148 3.31738C10.7148 1.66053 12.058 0.317383 13.7148 0.317383H20.572C22.2288 0.317383 23.572 1.66053 23.572 3.31738V14.4602C23.572 16.1171 22.2288 17.4602 20.572 17.4602H13.7148C13.281 17.4602 12.8571 17.7717 12.8571 18.2055V25.1745C12.8571 26.8314 14.2003 28.1745 15.8571 28.1745H22.7142C24.3711 28.1745 25.7142 29.5177 25.7142 31.1745V42.3174C25.7142 43.9742 24.3711 45.3174 22.7142 45.3174H9.87874C9.87154 45.3174 9.86433 45.3174 9.85712 45.3174H3C1.34315 45.3174 0 43.9743 0 42.3174V42.3174V31.1745V17.2461C0 15.5892 1.34315 14.2461 3 14.2461H9.85712C10.291 14.2461 10.7148 13.9346 10.7148 13.5007V3.31738Z" fill="#FFF"/></svg>,
        title: 'home_why_us_card_01_title',
        text: 'home_why_us_card_01_text',
    },
    {
        statusIcon: <svg width="26" height="46" viewBox="0 0 26 46" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M3 0.317383C1.34315 0.317383 0 1.66053 0 3.31738V17.3173V28.4601V42.3174C0 43.9742 1.34315 45.3174 3 45.3174H10C11.6569 45.3174 13 43.9742 13 42.3174V34.4601C13 32.8032 14.3431 31.4601 16 31.4601H22.7142C24.3711 31.4601 25.7142 30.117 25.7142 28.4601V17.3173C25.7142 15.6604 24.3711 14.3173 22.7142 14.3173H16C14.3431 14.3173 13 12.9741 13 11.3173V3.31738C13 1.66053 11.6569 0.317383 10 0.317383H3Z" fill="#E7E7E7"/></svg>,
        title: 'home_why_us_card_02_title',
        text: 'home_why_us_card_02_text',
    },
    {
        statusIcon: <svg width="24" height="46" viewBox="0 0 24 46" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M13.7148 0.317383C12.058 0.317383 10.7148 1.66053 10.7148 3.31738V13.5007C10.7148 13.9346 10.291 14.2461 9.85712 14.2461H3C1.34315 14.2461 0 15.5892 0 17.2461V42.3174C0 43.9743 1.34315 45.3174 3 45.3174H9.85712C11.514 45.3174 12.8571 43.9743 12.8571 42.3174V18.2055C12.8571 17.7717 13.281 17.4602 13.7148 17.4602H20.572C22.2288 17.4602 23.572 16.1171 23.572 14.4602V3.31738C23.572 1.66053 22.2288 0.317383 20.572 0.317383H13.7148Z" fill="#E7E7E7"/></svg>,
        title: 'home_why_us_card_03_title',
        text: 'home_why_us_card_03_text',
    },
    {
        statusIcon: <svg width="26" height="46" viewBox="0 0 26 46" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M3 0.317383C1.34315 0.317383 0 1.66053 0 3.31738V24.3174C0 25.9742 1.34315 27.3174 3 27.3174H6C7.65685 27.3174 9 28.6605 9 30.3174L9 42.3174C9 43.9742 10.3431 45.3174 12 45.3174H23C24.6569 45.3174 26 43.9742 26 42.3174L26 21.3174C26 19.6605 24.6569 18.3174 23 18.3174H19C17.3431 18.3174 16 16.9742 16 15.3174V3.31738C16 1.66053 14.6569 0.317383 13 0.317383H3Z" fill="#E7E7E7"/></svg>,
        title: 'home_why_us_card_04_title',
        text: 'home_why_us_card_04_text',
    },
    {
        statusIcon: <svg width="26" height="46" viewBox="0 0 26 46" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M2 3.31738C2 1.66053 3.34315 0.317383 5 0.317383H11.8571C13.514 0.317383 14.8571 1.66053 14.8571 3.31738V11.3704C14.8571 11.9291 15.4413 12.3174 16 12.3174H22.8571C24.514 12.3174 25.8571 13.6605 25.8571 15.3174V40.3888C25.8571 40.5455 25.8451 40.6995 25.8219 40.8497C25.7745 41.1572 25.7142 41.4646 25.7142 41.7757V42.3174C25.7142 43.9742 24.3711 45.3174 22.7142 45.3174H3C1.34315 45.3174 0 43.9742 0 42.3174V31.1745C0 29.5177 1.34315 28.1745 3 28.1745H10C11.6569 28.1745 13 26.8314 13 25.1745V18.4072C13 17.8485 12.4158 17.4602 11.8571 17.4602H5C3.34315 17.4602 2 16.1171 2 14.4602V3.31738Z" fill="#E7E7E7"/></svg>,
        title: 'home_why_us_card_05_title',
        text: 'home_why_us_card_05_text',
    },
];

const WhyUs = () => {
    const { t } = useTranslation();
    const [ current, setCurrent ] = useState(0);
    const setTab = (value) => {
        if (value === current) {
            setCurrent(-1);
            return;
        }
        setCurrent(value);
        return;
    };
    return (
        <section className="mt container">
            <div className={classes.whyUs}>
                <h2 className={`${classes.title} font-50`}>
                    {t('home_why_us_title')}
                </h2>
                <div className={`${classes.tabs} m-content`}>
                    {tabs.map((tab, index) =>
                        <div
                            key={index} 
                            className={`${classes.tab} ${index === current && classes.tabActive} no-select`}
                            onClick={() => setTab(index)}
                        >
                            <div className={classes.tabHeader}>
                                <p className={classes.tabHeaderIndex}>
                                    0{index + 1}
                                </p>
                                <h4 className={classes.tabHeaderTitle}>
                                    {t(tab.title)}
                                </h4>
                                <div className={classes.tabHeaderStatus}>
                                    {tab.statusIcon}
                                </div>
                            </div>
                            <div className={`${classes.tabContent} font-17`}>
                                <p>
                                    {t(tab.text)}
                                </p>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default WhyUs;
