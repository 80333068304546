import logo from '../assets/img/logo.png';
import img1 from '../assets/img/HomePage/Banner/animation_b.png';
import img2 from '../assets/img/HomePage/Banner/animation_s.png';
import img3 from '../assets/img/HomePage/Banner/dragon.png';
import img4 from '../assets/img/HomePage/bg.jpg';

export const images = [
    logo,
    img1,
    img2,
    img3,
    img4,
];
