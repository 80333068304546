import React from "react";
import classes from './Banner.module.scss';
import { useTranslation } from "react-i18next";
import Button from "../../GlobalComponents/Button/Button";
import Carousel from "./Carousel";
import dragon from '../../../assets/img/HomePage/Banner/dragon.png';

const Banner = () => {
    const { t } = useTranslation();
    return (
        <section className={classes.bannerWrap}>
            <img className={classes.dragon} src={dragon} alt=''/>
            <div className="container">
                <div className={classes.banner}>
                    <div className={classes.content}>
                        <h1 className={`${classes.title} font-100`}>
                            USB
                        </h1>
                        <h2 className={`${classes.subtitle} font-20`}>
                            {t('home_banner_text')}
                        </h2>
                        <div className={classes.btn}>
                            <Button toExchange>
                                {t('btn_change')}
                            </Button>
                        </div>
                    </div>
                    <Carousel />
                </div>
            </div>
            {/* <div className={classes.marquee}></div> */}
        </section>
    );
};

export default Banner;
